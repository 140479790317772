"use strict";

angular.module('app').controller('SettingsController', [
    '$scope', '$routeParams', '$http', '$q', 'appConfig', 'helperService', 'authService', function ($scope, $routeParams, $http, $q, appConfig, helperService, authService) {
        // INIT (set Default Values)

        // CONFIGURATION

        // PRIVATE FUNCTIONS


        // PUBLIC FUNCTIONS



        // ENVIRONMENT

        // RUN!!!
        $q.all([
            authService.init(),
        ]).then(function (returnValues) {
            $scope.CurrentUser = returnValues[0];
        });

    }
]);