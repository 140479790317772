module App {
    export class AppResolves {
        currentUser = ['authService', (authService: AuthService) =>
            authService.init(false)];

        summary = ['$http', 'appConfig', ($http: ng.IHttpService, appConfig: AppConfig) =>
            appConfig.request.Check.Summary($http)];

        detail = ['$http', '$route', 'appConfig', ($http: ng.IHttpService, $route: angular.route.IRouteService, appConfig: AppConfig) =>
            appConfig.request.Check.Detail($http, $route.current.params['Id'])];

        trans(t: string[]) {
            return ['$translate', ($translate: angular.translate.ITranslateService) =>
                $translate(t)];
        } 

    };


    angular.module('app', ['ngRoute', 'ngSanitize', 'kendo.directives', 'pascalprecht.translate', 'ngCookies', 'tmh.dynamicLocale', 'LocalStorageModule']);

    angular.module('app').config([
        '$locationProvider', '$routeProvider', '$httpProvider', '$translateProvider', '$translatePartialLoaderProvider', 'tmhDynamicLocaleProvider',
        function (
            $locationProvider : ng.ILocationProvider,
            $routeProvider: angular.route.IRouteProvider,
            $httpProvider: ng.IHttpProvider,
            $translateProvider: angular.translate.ITranslateProvider,
            $translatePartialLoaderProvider: any,
            tmhDynamicLocaleProvider: any) {
            // =============================
            // NG-HTTP
            // =============================
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            // Disable Caching for IE (Expires and/or If-Modified-Since can be inserted if further problems occur)
            //initialize get if not there
            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }

            //Cache Control: Prevent Caching of IE GET Requests
            //$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
            //$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-revalidate';
            $httpProvider.defaults.headers.get['Expires'] = '-1';

            $httpProvider.interceptors.push('authInterceptor');

            // =============================
            // NG-TRANSLATE
            // =============================
            // select default partial. More partials will be loaded by the controllers.
            $translatePartialLoaderProvider.addPart('Core');
            $translatePartialLoaderProvider.addPart('App');

            $translateProvider
                // partial translation tables from server
                .useLoader('$translatePartialLoader', { urlTemplate: '/Resources/{part}-{lang}.json' })
                //.useStaticFilesLoader({prefix: '/Resources/Core-',suffix: '.json'})

                // map machine locale to used locale, all non matched get the wildcard
                .registerAvailableLanguageKeys(['de', 'en'], {
                    'de_*': 'de',
                    'en*': 'en',
                    '*': 'en'
                })

                // get locale from browser settings
                //.preferredLanguage('en')
                .determinePreferredLanguage()

                // fallback if a single ID is not available in the used language
                // warning: search will not start at the beginning but from the currently active locale towards right direction
                .fallbackLanguage(['en'])

                // save manually selected language
                .useCookieStorage()

                // needed or you will get warnings in the browser
                .useSanitizeValueStrategy('sanitizeParameters')

                // log missing translations to console
                .useMissingTranslationHandlerLog();

            // =============================
            // DYNAMIC LOCALE
            // =============================
            tmhDynamicLocaleProvider.localeLocationPattern('/Scripts/angular-locale_{{locale}}.js');

            // =============================
            // NG-ROUTE
            // =============================
            //$locationProvider.html5Mode(true);
            var res = new AppResolves();
            $routeProvider
                // Public actions
                // ===============
                .when('/Home', {
                    template: '<app-home current-user="$resolve.currentUser"></app-home>',
                    resolve: {
                        currentUser: res.currentUser,
                    },
                })
                .when('/Login', {
                    template: '<app-login current-user="$resolve.currentUser"></app-login>',
                    resolve: {
                        currentUser: res.currentUser,
                    },
                })
                .when('/Login?returnUrl', {
                    template: '<app-login current-user="$resolve.currentUser"></app-login>',
                    resolve: {
                        currentUser: res.currentUser,
                    },
                })

                // Public Application Actions
                // ==========================



                // Admin Actions
                // =============
                .when('/LanguageCompare', {
                    templateUrl: '/Template/LanguageCompareTemplate',
                    controller: 'LanguageCompareController',
                    controllerAs: 'vm'
                })


                // Admin Actions
                // =============
                .when('/Users', {
                    templateUrl: '/Template/UsersTemplate',
                    controller: 'UserController',
                    controllerAs: 'vm',
                })
                .when("/Eventlog", {
                    template: '<eventlog></eventlog>'
                })


                // User Actions
                // ===========
                .when('/ChangePassword', {
                    templateUrl: '/Template/PasswordTemplate',
                    controller: 'ChangePasswordController',
                    controllerAs: 'vm',
                })
                .when('/Settings', {
                    templateUrl: '/Template/SettingsTemplate',
                    controller: 'SettingsController',
                    controllerAs: 'vm',
                })

                .when('/Summary', {
                    template: '<app-summary summary="$resolve.summary.data" current-user="$resolve.currentUser"></app-summary>',
                    resolve: {
                        summary: res.summary,
                        currentUser: res.currentUser,
                    },
                })
                .when('/Details/:Id', {
                    template: '<app-detail detail="$resolve.detail.data"></app-detail>',
                    resolve: {
                        detail: res.detail,
                    },
                })
                .when('/Download', {
                    template: '<app-download current-user="$resolve.currentUser"></app-download>',
                    resolve: {
                        currentUser: res.currentUser,
                    },
                })


                // Fallback
                // ===========
                .otherwise({
                    redirectTo: '/Summary',
                });


        }
    ]);

    angular.module('app').run([
        '$location', '$rootScope', '$window', 'authService', function($location:ng.ILocationService, $rootScope: ng.IRootScopeService, $window:ng.IWindowService, authService: AuthService) {
            authService.loadAuth($location.absUrl());

            $rootScope.$on('$routeChangeSuccess', function (event, current) {
                //console.debug(current);
                if (current.$$route) {
                    //$window.document.title = current.$$route.originalPath;
                    //$rootScope['title'] = current.$$route.originalPath;
                }
            });
            //$rootScope['kendo'] = kendo;
            //$rootScope['bootbox'] = bootbox; 
            //$rootScope['angular'] = angular;

            //connectionService.init();
        }
    ]);

}