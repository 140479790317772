module App {
    export class AppHomeController implements ng.IComponentController {
        currentUser: ICurrentUser;
        lng:string;

        static $inject: string[] = ['localeService'];
        constructor(private localeService: LocaleService) {
        }

        $onInit(): void {
            this.lng = this.localeService.getLocale();
        }
    }


    export class AppHomeComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppHomeController;

        templateUrl = '/Template/HomeTemplate';

    }

    angular.module("app").component("appHome", new AppHomeComponent());
} 
