module App {
    export class LanguageSwitcherController {
        static $inject: string[] = ['localeService'];

        constructor(private localeService: LocaleService) {
        }

        lang(locale: string) {
            this.localeService.setLocale(locale);
        }

    }


    export class LanguageSwitcherComponent implements ng.IComponentOptions {
        controller: any = LanguageSwitcherController;

        // https://www.iconfinder.com/iconsets/flags_gosquared
        template: string = `
                <div class="languages"> 
                    <img src="/Content/icons/en-uk.png" ng-click="$ctrl.lang('en')" /> 
                    <img src="/Content/icons/en-us.png" ng-click="$ctrl.lang('en')" /> 
                    <img src="/Content/icons/de-at.png" ng-click="$ctrl.lang('de')" /> 
                    <img src="/Content/icons/de-de.png" ng-click="$ctrl.lang('de')" /> 
                </div>
        `;
    }

    angular.module("app").component("languageSwitcher", new LanguageSwitcherComponent());
}