module App {
    //declare var moment: any;

    export class KendoFilterTranslation {
        Contains: string;
        StartsWith: string;
        DoesNotContain: string;
        Eq: string;
        DateLe: string;
        DateGe: string;
        Gt: string;
        Lt: string;
        Neq: string;
    }

    export class LocaleService {
        public static Factory($q: ng.IQService,
            $translate: angular.translate.ITranslateService,
            $translatePartialLoader: any,
            $route: any,
            tmhDynamicLocale: any) {
            return new LocaleService($q, $translate, $translatePartialLoader, $route, tmhDynamicLocale);
        }

        static $inject = ['$q', '$translate', '$translatePartialLoader', '$route', 'tmhDynamicLocale'];

        constructor(
            private $q: ng.IQService,
            private $translate: angular.translate.ITranslateService,
            private $translatePartialLoader: any,
            private $route: angular.route.IRouteService,
            private tmhDynamicLocale: any) {

        }

        private kendoFilterTranslation: KendoFilterTranslation;

        getKendoFilterTranslationObject() {
            return {
                string: {
                    contains: this.kendoFilterTranslation.Contains,
                    startswith: this.kendoFilterTranslation.StartsWith,
                    doesnotcontain: this.kendoFilterTranslation.DoesNotContain,
                        },
                date: {
                    eq: this.kendoFilterTranslation.Eq,
                    le: this.kendoFilterTranslation.DateLe,
                    ge: this.kendoFilterTranslation.DateGe,
                        },
                number: {
                    gt: this.kendoFilterTranslation.Gt,
                    lt: this.kendoFilterTranslation.Lt,
                    eq: this.kendoFilterTranslation.Eq
                },
                enums: {
                    eq: this.kendoFilterTranslation.Eq,
                    neq: this.kendoFilterTranslation.Neq,
                }
            }

        }

        private getKendoTranslations(): ng.IPromise<{}> {
            return this.$translate([
                    'Core.Kendo.Contains',
                    'Core.Kendo.StartsWith',
                    'Core.Kendo.DoesNotContain',
                    'Core.Kendo.Eq',
                    'Core.Kendo.DateLe',
                    'Core.Kendo.DateGe',
                    'Core.Kendo.Gt',
                    'Core.Kendo.Lt',
                    'Core.Kendo.Neq',
                ])
                .then(trans => {
                    this.kendoFilterTranslation = new KendoFilterTranslation();
                    this.kendoFilterTranslation.Contains = trans['Core.Kendo.Contains'];
                    this.kendoFilterTranslation.StartsWith = trans['Core.Kendo.StartsWith'];
                    this.kendoFilterTranslation.DoesNotContain = trans['Core.Kendo.DoesNotContain'];
                    this.kendoFilterTranslation.Eq = trans['Core.Kendo.Eq'];
                    this.kendoFilterTranslation.DateLe = trans['Core.Kendo.DateLe'];
                    this.kendoFilterTranslation.DateGe = trans['Core.Kendo.DateGe'];
                    this.kendoFilterTranslation.Gt = trans['Core.Kendo.Gt'];
                    this.kendoFilterTranslation.Lt = trans['Core.Kendo.Lt'];
                    this.kendoFilterTranslation.Neq = trans['Core.Kendo.Neq'];

                    return this.kendoFilterTranslation;
                });
        }

        private selectKendoLocale(requested:string): string {
            var kendoLocale = 'en-US';
            if (requested && requested.indexOf('de') === 0) kendoLocale = 'de-DE';

            return kendoLocale;
        }

        private selectMomentLocale(requested:string): string {
            // en basic is default, so call no method needed!
            var ret = 'en';
            if (requested && requested.indexOf('de') === 0) ret = 'de';
            return ret;
        }

        private selectAngularLocale(requested:string): string {
            var ret = "en";
            if (requested && requested.indexOf('de') === 0) ret = 'de';
            return ret;
        }

        getLocale(): string {
            return this.$translate.proposedLanguage();
        }

        setLocale(locale:string): void {
            this.$translate.use(locale);
            this.$route.reload();
        }

        init() {
            var deferred = this.$q.defer();

            // 1. angular-translate (from browsersetting or from cookie)
            var locale = this.getLocale();

            // 2. moment
            var momentLocale = this.selectMomentLocale(locale);
            if (momentLocale) moment.locale(momentLocale);

            // 3a. kendo load manual translations & calc language code
            this.getKendoTranslations();
            var kendoLocale = this.selectKendoLocale(locale);



            if (kendo.culture().name === kendoLocale) {
                deferred.resolve();
            } else {
                // 4. angular
                var angularLocale = this.selectAngularLocale(locale);
                this.tmhDynamicLocale.set(angularLocale)
                    .then(() => {
                        // 5. kendo
                        $.getScript("/Scripts/kendo.messages." + kendoLocale + ".min.js",
                            () => {
                                kendo.culture(kendoLocale);
                                console.debug("Selected Locale: angular=" + angularLocale + " angular-translate=" + locale + " kendo=" + kendoLocale + " moment=" + momentLocale);
                                deferred.resolve();
                            });
                    });
            }

            return deferred.promise;
        }

        private addPart(name:string) {
            this.$translatePartialLoader.addPart(name);
            this.$translate.refresh();
        }
    }

    angular.module("app").factory('localeService', ['$q', '$translate', '$translatePartialLoader', '$route', 'tmhDynamicLocale', LocaleService.Factory]);
}