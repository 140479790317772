module App {
    export class AppLoginController implements ng.IComponentController {
        //binding
        currentUser: ICurrentUser;

        //compute
        error: string;
        error2: string;
        username: string;
        password: string;
        returnUrl: string = '/';
        showPassLogon = false;
        private isRedirected: boolean = false;

        static $inject: string[] = ['$location', '$sce', '$routeParams', 'authService'];
        constructor(
            private $location: ng.ILocationService,
            private $sce: ng.ISCEService,
            private $routeParams: angular.route.IRouteParamsService,
            private authService: AuthService) {
        }

        $onInit(): void {
            if (angular.isString(this.$routeParams['returnUrl'])) {
                this.returnUrl = this.$routeParams['returnUrl'];
                this.isRedirected = true;
            }


        }


        login() {
            this.error = null;
            this.authService.login(this.username, this.password)
                .then(okResponse => {
                    this.$location.path(this.returnUrl);

                    // location does only work when then returns a result!
                    return okResponse;

                }, errResponse => {
                    console.debug(errResponse);
                    this.error = errResponse.Message;
                    this.error2 = angular.isString(this.error) ? this.$sce.trustAsHtml(this.error) : "";
                    this.password = "";
                    $('#password').focus();
                    return errResponse;
                });
        }

        logout() {
            this.authService.logout();
        }
        // ========================================
        // PRIVATE
        // ========================================

        
    }


    export class AppLoginComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppLoginController;

        templateUrl = '/Template/LoginTemplate';

    }

    angular.module("app").component("appLogin", new AppLoginComponent());
}
 

