module App {
    export class CoreDataService {
        public static Factory($http: ng.IHttpService,
            $q: ng.IQService,
            $filter: ng.IFilterService,
            appConfig: AppConfig) {
            return new CoreDataService($http, $q, $filter, appConfig);
        }

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $filter: ng.IFilterService,
            private appConfig: AppConfig) {

        }

        // LIST DATA
        // ===========
        private data: {[k:string]:any} = {};

        loadList(apiRequest:ng.IHttpPromise<{}>, key: string): ng.IPromise<IList[]> {
            var deferred = this.$q.defer();
            apiRequest.then(okResponse => {
                this.data[key] = okResponse.data;
                deferred.resolve(okResponse.data);
            }, errResponse => {
                console.debug(errResponse);
                deferred.reject(errResponse);
            });
            return deferred.promise;
        }

        getData(entity: string, withoutValue: boolean): any[] {
            if (withoutValue) {
                var res:any[] = [];
                angular.forEach(this.data[entity], val => {
                    res.push({ text: val.text });
                });
                return res;
            } else {
                return this.data[entity];
            }
        }










    }

    angular.module("app").service("coreDataService", ['$http', '$q', '$filter', 'appConfig', CoreDataService.Factory]);
}