module App {
    export class LeftMenuController implements ng.IComponentController {
        //compute
        //canImport = false;
        //currentUser: ICurrentUser;

        static $inject: string[] = ['$location', 'authService'];
        constructor(private $location:  ng.ILocationService, private authService: AuthService) {
        }

        $onInit(): void {
            //this.authService.init(false).then(cu => {
                //this.currentUser = cu;
            //    this.canImport = this.authService.inRole('EventManager');
            //});
        }

        logout() {
            this.authService.logout();
        }


        // ========================================
        // PRIVATE
        // ========================================

        
    }


    export class LeftMenuComponent implements ng.IComponentOptions {
        controller: any = LeftMenuController;

        templateUrl: any = '/Template/LeftMenu';
    }

    angular.module("app").component("leftMenu", new LeftMenuComponent());
}
