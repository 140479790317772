module App {
    export class MyTemplateController implements ng.IComponentController {
        //binding
        currentUser: ICurrentUser;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class MyTemplateComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
        currentUser: '<'
    };

    controller = MyTemplateController;
    //templateUrl='';
    template = `
            
        `;
}

angular.module("app").component("myTemplate", new MyTemplateComponent());
}