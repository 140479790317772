module App {
    export interface IOauthToken {
        access_token: string;
        userName: string;
        token_type: string;
        expires_in: number;
        ".expires"?: Date;
        ".issued"?: Date;
    }
    //////////////////////////
    // Enums
    //////////////////////////
    export enum StatusType {
        NoValue = 0,
        Success = 1,
        Warning = 2,
        Error = 3
    }

    //////////////////////////
    // API View Models
    //////////////////////////

    export interface IDataSourceResult<T> {
        Data: T[];
        Total: number;
    }

    export interface IList {
        value: number,
        text: string,
    }

    export interface ICurrentUser {
        UserId: string,
        Username: string,
        IsAuthenticated: boolean,
        Roles: string[],
        IsAuthenticating: boolean,
        IsLoaded: boolean,
    }


    export interface ICompanyViewModel {
        Id: number;
        Name: string;
        DomainName: string;
        Email: string;
        Devices: IDeviceViewModel[];
        Key: string;
        WorstStatus: StatusType;
        MaxAgeInDays: number;
    }

    export interface IDeviceViewModel {
        Id: number;
        Name: string;
        IpAddress: string;
        IpAddressPublic: string;
        DeviceStatus: StatusType;
        Checks: ICheckViewModel[];
        CompanyId: number;
        CompanyName: string;
        LastConnection: Date | null;

        Manufacturer: string;
        Model: string;
        OperatingSystem: string;
        ClientVersion: string;
        Description: string;
    }

    export interface ICheckViewModel {
        Id: number;
        CheckType: string;
        CheckStatus: StatusType;
        ResultDescription: string;
        InfoDescription: string;
        LastUpdate: Date;
        Items: ICheckItemViewModel[];
        DeviceId: number;
        DeviceName: string;
    }

    export interface ICheckItemViewModel {
        Id: number;
        DateSubmit: Date;
        IsCurrent: boolean;
        ItemType: string;
        ItemStatus: StatusType;

        NumValue: number | null;
        BoolValue: boolean | null;
        TextValue: boolean | null;
        DateValue: Date | null;
        PercentValue: number | null;

        ResultDescription: string;
        InfoDescription: string;

        CheckId: number;
        CheckName: string;
    }

    export interface IDownloadViewModel {
        FileName: string;
        Size: number;
        Hash: string;
        Timestamp: Date;
        Version: string;
        Url: string;
    }




}