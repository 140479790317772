module App {
    export class EventlogController implements ng.IComponentController {
        //binding
        //currentUser: ICurrentUser;

        //compute
        gridOptions: kendo.ui.GridOptions;
        grid: kendo.ui.Grid;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        getGridOptions(): kendo.ui.GridOptions {
            var self = this;
        return {
            // 1. DATASOURCE
            "dataSource": {
                "type": 'webapi',
                "transport": {
                    "read": this.appConfig.helper.http('get', this.appConfig.request.Eventlog.LogUrl, this.authService.getBearerTokenObject()),
                },
                "serverPaging": true,
                "pageSize": 20,
                "serverSorting": true,
                "serverFiltering": true,
                "sort": { "field": "DateTime", "dir": "desc" },
                "filter": [],
                "error": function (e:any) {
                    self.authService.kendoError(e, self.grid);
                },
                "requestEnd": function (e:any) {
                    self.helperService.toLocalTime(e, "DateTime");
                },

                "schema": {
                    model: {
                        "id": "Id",
                        "fields": {
                            "Id": { "type": "number" },
                            "DateTime": { "type": "date" },
                            "Action": { "type": "string" },
                            "User": { "type": "string" },
                            "Details": { "type": "string" },
                            "IpAddress": { "type": "string" },
                        }
                    },
                    "data": "Data",
                    "total": "Total",
                    "errors": "Errors",

                }

            },

            // 2. COLUMNS
            "columns": [
                {
                    "title": "Datum / Zeit",
                    "field": "DateTime",
                    "format": "{0:g}",
                    "filterable": true
                }, {
                    "title": "IP Adresse",
                    "field": "IpAddress",
                }, {
                    "title": "Aktion",
                    "field": "Action",
                    "filterable": true,
                    "values": [
                        { value: 3, text: "Anmeldung" },
                        { value: 6, text: "System" },
                    ]
                }, {
                    "title": "Benutzer",
                    "field": "User",
                    "filterable": true
                }, {
                    "title": "Details",
                    "field": "Details",
                    "template": "<translate-content input='dataItem.Details'></translate-content>",
                    "filterable": true
                }
            ],

            // 3. FEATURES
            "pageable": true,
            "sortable": true,
            "scrollable": false,
            "filterable": true,

            // 4. EVENTS
        }
    };



    }


    export class EventlogComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            //currentUser: '<'
        };

        controller = EventlogController;
        //templateUrl='';
        template = `
            <h1>
            <a href="#/Settings" class="btn btn-default">   
                <span class="glyphicon glyphicon-arrow-left"></span> <span translate>Core.Buttons.GoBack</span> 
            </a> <span translate>App.Eventlog.Header</span></h1>

            <div kendo-grid="$ctrl.grid" k-options="$ctrl.gridOptions"></div>
        `;
    }

    angular.module("app").component("eventlog", new EventlogComponent());
}

