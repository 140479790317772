module App {
    export class AppSummaryController implements ng.IComponentController {
        //binding
        currentUser: ICurrentUser;
        summary: ICompanyViewModel[];

        //compute
        canEdit: boolean;
        statusTypes = StatusType;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$element', '$timeout', '$http', '$route', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $element: ng.IRootElementService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private $route: ng.route.IRouteService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            console.debug("summary", this.summary);
            if (this.authService.inRole('Administrators')) {
                this.canEdit = true;
            }
            $(this.$element).tooltip(); 
        }

        $onChanges() {
            if (this.summary) {
                this.summary.sort((a: ICompanyViewModel, b: ICompanyViewModel) => {
                    let aOverAge = a.MaxAgeInDays > 3 ? 1 : 0;
                    let bOverAge = b.MaxAgeInDays > 3 ? 1 : 0;

                    if (aOverAge > bOverAge) return -1;
                    if (aOverAge < bOverAge) return 1;

                    if (a.WorstStatus > b.WorstStatus) return -1;
                    if (a.WorstStatus < b.WorstStatus) return 1;

                    return 0;
                });

            }
        }
        // ========================================
        // PUBLIC
        // ========================================
        deleteDevice(deviceId: number) {
            this.helperService.confirm('Delete Device?', 'This cannot be made undone.',
                () => {
                    this.appConfig.request.Check.RemoveDevice(this.$http, deviceId).then(ok => {
                        this.$route.reload();
                    });
                }, null, false);

        }

        setDescription(device: IDeviceViewModel) {
            this.helperService.prompt("Description", device.Description, (input:string) => {
                device.Description = input;
                this.appConfig.request.Check.SetDevice(this.$http, device);
            }, false);
        }

        addCompany() {
            this.helperService.prompt("New Company", "", (input: string) => {
                let c: ICompanyViewModel = {
                    Name: input,
                    Devices: null,
                    DomainName: null,
                    Email: null,
                    Id: null,
                    Key: null,
                    MaxAgeInDays: 0,
                    WorstStatus: null
                };
                this.appConfig.request.Check.AddCompany(this.$http, c).then(ok => {
                    this.$route.reload();
                });
            }, false);
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppSummaryComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            summary: '<'
        };

        controller = AppSummaryController;
        templateUrl='/Template/SummaryTemplate';
    }

    angular.module("app").component("appSummary", new AppSummaryComponent());
}