module App {
    export class AppHelpController implements ng.IComponentController {
        //binding
        currentUser: ICurrentUser;

        //compute
        tab: kendo.ui.TabStrip;
        lng: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.lng = this.localeService.getLocale();

            if (this.$location.search().tab) {
                var tab = $('#' + this.$location.search().tab);
                if (tab) tab.addClass("k-state-active");
            } else {
                $('div[kendo-tab-strip]').find('li').first().addClass("k-state-active");
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        tabOptions: kendo.ui.TabStripOptions = {
            tabPosition: "left",
            animation: { open: { effects: "fadeIn" } },
            select: (e: any) => {
                this.$timeout(() => {
                    this.$location.search("tab", e.item.id);
                });
            }
        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppHelpComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppHelpController;
        templateUrl ='/Template/HelpTemplate';
    }

    angular.module("app").component("appHelp", new AppHelpComponent());
}