module App {
    export class AppConfig {
        public request = {
            Login: {
                GetCurrentUser($http: ng.IHttpService): ng.IHttpPromise<ICurrentUser> {
                    return $http.get("api/login/currentuser/");
                },
                ChangePassword($http: ng.IHttpService, oldPass: string, newPass1: string, newPass2: string): ng.IHttpPromise<{}> {
                    return $http.post("api/login/changepassword", { OldPass: oldPass, NewPass1: newPass1, NewPass2: newPass2 });
                },
                TokenLogin($http: ng.IHttpService, username: string, password: string): ng.IHttpPromise<IOauthToken> {
                    var data = "grant_type=password&username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password);
                    return $http.post("/token", data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
                },
                SwapToken($http: ng.IHttpService, externalAccessToken: string) {
                    return $http.post("api/login/swaptoken", { ExternalAccessToken: externalAccessToken });
                }

            },
            Identity: {
                User: {
                    Url: "api/Identity/users/",
                    GetUserById($http: ng.IHttpService, id: string): ng.IHttpPromise<any> {
                        return $http.get("api/identity/users/" + id);
                    },
                    ResetPassword($http: ng.IHttpService, id: string, newPass1: string, newpass2: string): ng.IHttpPromise<any> {
                        return $http.post("api/identity/users/resetpassword/", { id: id, newPass1: newPass1, newPass2: newpass2 });
                    },
                },
                Role: {
                    GetAllRoles($http: ng.IHttpService): ng.IHttpPromise<any> {
                        return $http.get("api/identity/roles");
                    },
                    Url: "api/identity/roles",
                },
                Membership: {
                    Get($http: ng.IHttpService, id: string): ng.IHttpPromise<any> {
                        return $http.get("api/Identity/membership/?id=" + id);
                    },
                    Add($http: ng.IHttpService, userId: string, roleId: string): ng.IHttpPromise<any> {
                        return $http.post("api/Identity/membership/" + userId + "/" + roleId, null);
                    },
                    Remove($http: ng.IHttpService, userId: string, roleId: string): ng.IHttpPromise<any> {
                        return $http.delete("api/Identity/membership/" + userId + "/" + roleId);
                    },
                }
            },
            CoreData: {
                Enums($http: ng.IHttpService): ng.IHttpPromise<{ RaceResultType: {[key:string]:string}}> {
                    return $http.get("api/coredata/enums/");
                },
            },


            Eventlog: {
                LogUrl: "api/eventlog/system",
            },


            Check: {
                Summary($http: ng.IHttpService): ng.IHttpPromise<ICompanyViewModel[]> {
                    return $http.get("api/check/summary");
                },
                Detail($http: ng.IHttpService, checkId: number): ng.IHttpPromise<ICheckViewModel> {
                    return $http.get("api/check/detail/" + checkId);
                },
                RemoveDevice($http: ng.IHttpService, deviceId: number): ng.IHttpPromise<{}> {
                    return $http.delete("api/check/device/" + deviceId);
                },
                SetDevice($http: ng.IHttpService, device: IDeviceViewModel): ng.IHttpPromise<{}> {
                    return $http.put("api/check/device", device);
                },
                AddCompany($http: ng.IHttpService, company: ICompanyViewModel): ng.IHttpPromise<ICompanyViewModel> {
                    return $http.post("api/check/company", company);
                },

            },

            Download: {
                Agent: {
                    Check($http: ng.IHttpService): ng.IHttpPromise<IDownloadViewModel> {
                        return $http.get("api/download/agent/check");
                    },
                    DownloadUrl: "api/download/agent",
                },
                Updater: {
                    Check($http: ng.IHttpService): ng.IHttpPromise<IDownloadViewModel> {
                        return $http.get("api/download/updater/check");
                    },
                    DownloadUrl: "api/download/updater",
                },
            }



        };

        public helper = {
            http(method: 'get'|'post'|'put'|'delete', url: string, bearerTokenObject: any) {
                var ret = {
                    url: url,
                    type: method.toUpperCase(),
                    //data: data,
                    headers: bearerTokenObject
                }
                return ret;
            }

        }

    }

    angular.module("app").value("appConfig", new AppConfig());


}