module App {
    export class AppDownloadController implements ng.IComponentController {
        //binding
        currentUser: ICurrentUser;

        //compute
        canEdit: boolean;
        agent: IDownloadViewModel;
        updater: IDownloadViewModel;
        //agentVersion: string;
        //updaterVersion: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.canEdit = this.authService.inRole('Administrators');
            this.getFiles();
        }
        // ========================================
        // PUBLIC
        // ========================================
        uploadAgentOptions = {
            async: {
                saveUrl: this.appConfig.request.Download.Agent.DownloadUrl,
                autoUpload: true,
                batch: true,
            },
            error: (e:any) => {
                const error = angular.fromJson(e.XMLHttpRequest.response);
                if (angular.isString(error.Message)) {
                    bootbox.alert(error.Message);
                } else {
                    bootbox.alert("There has been an error on uploading the file.");
                }
            },
            validation: {
                allowedExtensions: [".msi"]
            },
            complete: ()=> {
                this.getFiles();
            },
            upload: this.getOnUpload(this)
        }

        uploadUpdaterOptions = {
            async: {
                saveUrl: this.appConfig.request.Download.Updater.DownloadUrl,
                autoUpload: true,
                batch: true,
            },
            error: (e:any) => {
                const error = angular.fromJson(e.XMLHttpRequest.response);
                if (angular.isString(error.Message)) {
                    bootbox.alert(error.Message);
                } else {
                    bootbox.alert("There has been an error on uploading the file.");
                }
            },
            validation: {
                allowedExtensions: [".msi"]
            },
            complete: () => {
                this.getFiles();
            },
            upload: this.getOnUpload(this)

        }



    

        // ========================================
        // PRIVATE
        // ========================================
        private getFiles() {
            this.appConfig.request.Download.Agent.Check(this.$http).then(ok => {
                this.agent = ok.data;
            });
            this.appConfig.request.Download.Updater.Check(this.$http).then(ok => {
                this.updater = ok.data;
            });
        }

        private getOnUpload(ctrl: AppDownloadController): (e: any) => any {
            return function (e: any) {
                var bearer = ctrl.authService.getBearerTokenObject();
                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState === 1 /* OPENED */) {
                            xhr.setRequestHeader("Authorization", bearer['Authorization']);
                        }
                    });

                }
            }
        }

    }


    export class AppDownloadComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppDownloadController;
        templateUrl='/Template/DownloadTemplate';
    }

    angular.module("app").component("appDownload", new AppDownloadComponent());
}