module App {
    export class StatusButtonController implements ng.IComponentController {
        //binding
        status: number;
        text: string;
        link: number;

        //compute
        css: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            switch (this.status) {
            case 1:
                this.css = "btn-success";
                break;
            case 2:
                this.css = "btn-warning";
                break;
            case 3:
                this.css = "btn-danger";
                break;
            default:
                this.css = "btn-default";
                break;
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class StatusButtonComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            status: '<',
            text: '<',
            link: '<',
        };

        controller = StatusButtonController;
        //templateUrl='';
        template = `
            <a ng-if="$ctrl.link" class="btn btn-xs {{$ctrl.css}}" ng-href="#/Details/{{$ctrl.link}}">{{$ctrl.text}}</a>
        `;
    }

    angular.module("app").component("statusButton", new StatusButtonComponent());
}