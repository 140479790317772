"use strict";

//angular.module('changePasswordModule', ['kendo.directives'])
angular.module('app').controller('ChangePasswordController', [
        '$scope', '$routeParams', '$http', '$q', '$translate', 'appConfig', 'authService', 'helperService',
        function ($scope, $routeParams, $http, $q, $translate, appConfig, authService, helperService) {
            $scope.trans = {};
            $scope.CurrentUser = authService.CurrentUser;
            $scope.view = "select"; // disable | select (-1) | deny (0) | regsw (1) | reghw (2)


            $scope.submit = function () {
                $scope.errors = {}; 
                appConfig.request.Login.ChangePassword($http, $scope.oldPassword, $scope.newPassword1, $scope.newPassword2).then(function(okResponse) { 
                    bootbox.alert($scope.trans.Success);
                }, function(e) { helperService.errorMsg(e) });

                $scope.oldPassword = "";
                $scope.newPassword1 = "";
                $scope.newPassword2 = "";
            }



            // ENVIRONMENT


            // RUN!!!
            $q.all([
                authService.init(),
                $translate('Core.Password.Success'),
            ]).then(function (returnValues) {
                $scope.CurrentUser = returnValues[0];
                $scope.trans.Success = returnValues[1];
            });



        }
    ])

;