"use strict";

angular.module("app").controller('LanguageCompareController', [
    '$scope', '$http', '$q', 'helperService', function ($scope, $http, $q, helperService) {
        // INIT
        $scope.languages = ['en', 'de'];
        $scope.diff = [];
        $scope.counter = 0;

        // FUNCTIONS
        $scope.slaveLanguages = function () {
            var parts = [];
            for (var i = 1; i < $scope.languages.length; i++) {
                parts.push($scope.languages[i]);
            }
            return parts.join();
        }
        var compareRecursive = function (namespace, master, slaves, slaveNames) {
            //console.log("entering namespace " + namespace);
            for (var key in master) {

                // Compare Subobjects Recursively
                if (angular.isObject(master[key])) {
                    var newSlaves = [];
                    var newSlaveNames = [];

                    for (var slaveId = 0; slaveId < slaves.length; slaveId++) {
                        if (!slaves[slaveId][key]) {
                            $scope.diff.push({ namespace: namespace + "." + key, lang: slaveNames[slaveId], error: "object is missing" });
                        } else if (!angular.isObject(slaves[slaveId][key])) {
                            $scope.diff.push({ namespace: namespace + "." + key, lang: slaveNames[slaveId], error: "is not an object" });

                        } else {
                            newSlaves.push(slaves[slaveId][key]);
                            newSlaveNames.push(slaveNames[slaveId]);
                        }

                    }

                    compareRecursive(namespace + "." + key, master[key], newSlaves, newSlaveNames);


                } else if (angular.isString(master[key])) {
                    //console.log("KEY:", key);
                    $scope.counter++;
                    for (var s = 0; s < slaves.length; s++) {
                        if (!slaves[s][key]) {
                            $scope.diff.push({ namespace: namespace + "." + key, lang: slaveNames[s], error: "string is missing" });
                        } else if (!angular.isString(slaves[s][key])) {
                            $scope.diff.push({ namespace: namespace + "." + key, lang: slaveNames[s], error: "is not a string" });
                        }
                    }
                } else {
                    $scope.diff.push({ namespace: namespace + "." + key, lang: $scope.languages[0], error: "is either not an object or string" });
                }
            }
        }


        var compareObject = function (data) {
            $scope.diff = [];
            $scope.counter = 0;

            var master = data[0];
            var slaves = [];
            var slaveNames = [];
            for (var i = 1; i < data.length; i++) {
                slaves.push(data[i]);
                slaveNames.push($scope.languages[i]);
            }

            compareRecursive("", master, slaves, slaveNames);

        }



        $scope.compareFile = function (name) {
            $scope.selected = name;
            var promises = [];
            angular.forEach($scope.languages, function (l) {
                promises.push($http.get("/Resources/" + name + "-" + l + ".json"));
            });
            $q.all(promises).then(function (okResults) {
                var content = [];
                angular.forEach(okResults, function (i) {
                    content.push(i.data);
                });
                compareObject(content);
            }, function (errors) {
                console.log(errors);
                helperService.alert("Error loading translation files. Please Check console output.");
            });

        }
        // ENVIRONMENT

        //RUN!
    }
]);