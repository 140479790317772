module App {
    export class TopMenuController implements ng.IComponentController {
        //binding

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================
        logout() {
            this.authService.logout();
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class TopMenuComponent implements ng.IComponentOptions {

        controller = TopMenuController;
        templateUrl='Template/TopMenu';
    }

    angular.module("app").component("topMenu", new TopMenuComponent());
}