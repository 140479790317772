module App {
    export class AppDetailController implements ng.IComponentController {
        //binding
        detail: ICheckViewModel;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            console.debug("detail", this.detail);
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppDetailComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            detail: '<'
        };

        controller = AppDetailController;
        templateUrl = '/Template/DetailTemplate';
    }

    angular.module("app").component("appDetail", new AppDetailComponent());
}