angular.module('app').filter('groupBy', ['$filter', function ($filter) {
    return function (input, field) {
        if (!input || !field) return input;
        var ret = {};
        angular.forEach(input, function (val) {
            var groupName = val[field];
            if (!groupName) groupName = '?';

            if (!(groupName in ret)) ret[groupName] = [];
            ret[groupName].push(input);
        });
        return ret;
    };
}]);