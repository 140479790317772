"use strict";

//angular.module('accountModule', ['kendo.directives'])
angular.module('app').controller('UserController', [
    '$scope', '$routeParams', '$http', '$q', 'appConfig', 'helperService', 'authService', function($scope, $routeParams, $http, $q, appConfig, helperService, authService) {

        $scope.openPasswordWindow = function(dataItem) {
            $scope.dataItem = dataItem;
            $scope.ResetPasswordWindow.center().open();
        };

        var userGridOptions = function() {
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": appConfig.helper.http('get', appConfig.request.Identity.User.Url, authService.getBearerTokenObject()),
                        "update": appConfig.helper.http('put', appConfig.request.Identity.User.Url, authService.getBearerTokenObject()),
                        "create": appConfig.helper.http('post', appConfig.request.Identity.User.Url, authService.getBearerTokenObject()),
                        "destroy": appConfig.helper.http('delete', appConfig.request.Identity.User.Url, authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "sort": [{ "field": "UserName", "dir": "asc" }],
                    "filter": [], 
                    "error": function(e) {
                        authService.kendoError(e, $scope.userGrid);
                    },
                    "requestEnd": function (e) {
                        helperService.toLocalTime(e, "LastLogon");
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "LastLogon": { "editable": false, "type": "date", "defaultValue": null }, 
                                "UserName": { "type": "string" },
                                "Email": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },

                },

                //2. Columns
                "columns": [
                    {
                        "title": "Id",
                        "hidden": true,
                        "field": "Id",
                    }, {
                        "title": "Anmeldename",
                        "field": "UserName"
                    }, {
                        "title": "E-Mail",
                        "field": "Email"
                    }, {
                        "title": "Aktiv",
                        "template": '<input type="checkbox" id="IsEnabled" #= IsEnabled ? checked="checked" : "" # disabled="disabled" />',
                        "field": "IsEnabled",
                        "editor": '<input class="check-box" data-val="true" data-val-required="The Aktiv field is required." id="IsEnabled" name="IsEnabled" type="checkbox" value="true" /><input name="IsEnabled" type="hidden" value="false" /><span class="field-validation-valid" data-valmsg-for="IsEnabled" data-valmsg-replace="true"></span>'
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "edit", "buttonType": "ImageAndText", "text": { "cancel": "Abbrechen", "update": "Speichern", "edit": "Bearbeiten" } },
                            { "name": "destroy", "buttonType": "ImageAndText", "text": "Löschen" },
                        ]
                    }
                ],

                //3. Features
                "sortable": true,
                "scrollable": false,
                "editable": {
                    "confirmation": "Sind Sie sicher, dass Sie diesen Benutzer löschen wollen?",
                    "confirmDelete": "Löschen",
                    "cancelDelete": "Abbrechen",
                    "mode": "inline",
                    "create": true,
                    "update": true,
                    "destroy": true
                },
                "toolbar": [{ "name": 'create', "buttonType": "ImageAndText", "text": "Neuen Benutzer erstellen" }],

                //4. Events
                "detailInit": function (e) {
                    $scope.loadDetails(e.data);
                }
            }
        };

        //var roleGridOptions = function() {
        //    return {
        //        //1. Datasource
        //        "dataSource": {
        //            "type": 'webapi', 
        //            "transport": {
        //                "read": appConfig.helper.get(appConfig.request.Identity.Role.Url),
        //                "update": appConfig.helper.put(appConfig.request.Identity.Role.Url),
        //                "create": appConfig.helper.post(appConfig.request.Identity.Role.Url),
        //                "destroy": appConfig.helper.delete(appConfig.request.Identity.Role.Url),
        //            },
        //            "serverPaging": true,
        //            "serverSorting": true,
        //            "serverFiltering": true,
        //            "serverGrouping": true,
        //            "serverAggregates": true,
        //            "sort": [{ "field": "Name", "dir": "asc" }],
        //            "filter": [],
        //            "error": function (e) {
        //                authService.kendoError(e);
        //            },
        //            "schema": {
        //                model: {
        //                    "id": "Id",
        //                    "fields": {
        //                        "Id": { "type": "string" },
        //                        "Name": { "type": "string" },
        //                    }
        //                },
        //                "data": "Data",
        //                "total": "Total",
        //                "errors": "Errors",
        //            }
        //        },
        //        //2. Columns
        //        "columns": [
        //            {
        //                "title": "Id",
        //                "hidden": true,
        //                "field": "Id"
        //            }, {
        //                "title": "Gruppenname",
        //                "field": "Name"
        //            }, {
        //                "width": 200,
        //                "command": [
        //                    { "name": "edit", "buttonType": "ImageAndText", "text": { "cancel": "Abbrechen", "update": "Speichern", "edit": "Umbenennen" } },
        //                    { "name": "destroy", "buttonType": "ImageAndText", "text": "Löschen" }
        //                ]
        //            }
        //        ],
        //        //3. Features
        //        "sortable": true,
        //        "scrollable": false,
        //        "editable": {
        //            "confirmation": "Sind Sie sicher, dass Sie diese Rolle löschen wollen? Die enthaltenen Benutzer werden nicht gelöscht.",
        //            "confirmDelete": "Löschen",
        //            "cancelDelete": "Abbrechen",
        //            "mode": "inline",
        //            "create": true,
        //            "update": true,
        //            "destroy": true
        //        },
        //        "toolbar": [{ "name": 'create', "buttonType": "ImageAndText", "text": "Neue Rolle erstellen" }],
        //        //4. Events
        //    }
        //};

        $scope.submitPasswordChange = function() {
            appConfig.request.Identity.User.ResetPassword($http, $scope.dataItem.Id, $scope.pass.new1, $scope.pass.new2).then(function(okResponse) {
                $scope.closePasswordWindow();
                bootbox.alert("Das Passwort wurde erfolgreich geändert.");
            }).catch(function(errResponse) {
                $scope.closePasswordWindow();
                helperService.errorMsg(errResponse);
            });

        }
        //$scope.closePasswordWindow();

        $scope.closePasswordWindow = function() {
            $scope.ResetPasswordWindow.center().close();
            $scope.pass = {};
        }

        $scope.loadDetails = function (dataItem) {
            appConfig.request.Identity.Membership.Get($http, dataItem.Id).then(function (okResponse) {
                dataItem.Roles = [];
                angular.forEach($scope.Roles, function(r) {
                    dataItem.Roles.push(
                        {
                            Id: r.Id,
                            Name: r.Name,
                            IsEnabled: function () {
                                var ret = false;
                                angular.forEach(okResponse.data, function(nam, rid) {
                                    if (rid === r.Id) {
                                        ret = true;
                                        return;
                                    }
                                });
                                return ret;
                            }()
                        }
                    );
                });
            });
        }

        $scope.onRoleClick = function(dataItem, role) {
            if (role.IsEnabled) {
                appConfig.request.Identity.Membership.Remove($http, dataItem.Id, role.Id).then(function(oKResponse) {
                    role.IsEnabled = false;
                }, function(e) { helperService.errorMsg(e) });
            } else {
                appConfig.request.Identity.Membership.Add($http, dataItem.Id, role.Id).then(function(okResponse) {
                    role.IsEnabled = true;
                }, function(e) { helperService.errorMsg(e) });
                }
        }


        // RUN!!!
        $q.all([
            authService.init(),
            appConfig.request.Identity.Role.GetAllRoles($http),
        ]).then(function(returnValues) {
            $scope.CurrentUser = returnValues[0];
            $scope.userGridOptions = userGridOptions();
            $scope.Roles = returnValues[1].data.Data;
            //$scope.roleGridOptions = roleGridOptions();
        });


    }
]);