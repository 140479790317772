module App {
    export class StatusLightController implements ng.IComponentController {
        //binding
        status: number;
        text: string;

        //compute
        color: string;
        glyph: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            switch (this.status) {
            case 1:
                this.color = "text-success";
                this.glyph = "glyphicon-ok-circle";
                break;
            case 2:
                this.color = "text-warning";
                this.glyph = "glyphicon-warning-sign";
                break;
            case 3:
                this.color = "text-danger";
                this.glyph = "glyphicon-remove-circle";
                break;
            default:
                this.color = "text-muted";
                this.glyph = "glyphicon-record";
                break;
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class StatusLightComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            status: '<',
            text: '<',
        };

        controller = StatusLightController;
        //templateUrl='';
        template = `
            <span class="glyphicon {{$ctrl.glyph}} {{$ctrl.color}}"></span>
            <span class="{{$ctrl.color}}">{{$ctrl.text}}</span>
        `;
    }

    angular.module("app").component("statusLight", new StatusLightComponent());
}