module App {
    export class TextEditController implements ng.IComponentController {
        //bindings
        text: string;
        canEdit: boolean;
        //atSave: (text: string)=>ng.IPromise<any>;
        atSave: Function;

        //computed
        safeText: string;
        editMode: boolean = false;
        textOptions: any;

        // private state helper
        private origInfo: string;

        // ========================================
        // INIT
        // ========================================
        static $inject: string[] = ['$sce', '$http', 'appConfig', 'helperService'];

        constructor(
            private $sce: ng.ISCEService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.textOptions = this.getTextOptions();
        }


        $onChanges(onChangesObj: angular.IOnChangesObject): void {
            if (onChangesObj['text']) this.secureText(onChangesObj['text'].currentValue);
        }

        // ========================================
        // PUBLIC
        // ========================================
        editInfo() {
            this.origInfo = this.text;
            this.editMode = true;

        }

        cancelInfo() {
            this.text = this.origInfo;
            this.secureText(this.text);
            this.editMode = false;

        }

        saveInfo() {
            this.atSave({  
                appConfig: this.appConfig,
                $http: this.$http,
                data: this.text
            }).then(() => {
                this.editMode = false;
                this.secureText(this.text);
            }, e=>this.helperService.errorMsg(e));
        }


        // ========================================
        // PRIVATE
        // ========================================
        private getTextOptions(): any {
            return {
                tools: [
                    "bold", "italic", "underline", "strikethrough",
                    "justifyLeft", "justifyCenter", "justifyRight", "justifyFull",
                    "insertUnorderedList", "insertOrderedList", "indent", "outdent",
                    "createLink", "unlink",
                    "insertImage", //"insertFile", //"subscript", //"superscript",
                    "createTable", "addRowAbove", "addRowBelow", "addColumnLeft", "addColumnRight", "deleteRow", "deleteColumn",
                    "viewHtml", "cleanFormatting", "fontSize",
                    "fontName", "formatting", "foreColor", "backColor", //"print"
                ],
                resizable: {
                    min: 500
                }
            }
        }

        private secureText(text: string) {
            if (text) {
                this.safeText = this.$sce.trustAsHtml(text);
            } else {
                this.safeText = "";
            }
        }

    }


    export class TextEditComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            text: '<',
            canEdit: '<',
            atSave: '&',
        };

        controller = TextEditController;
        template = `
        <div id="infoTabView" ng-show="!$ctrl.editMode && ($ctrl.canEdit || $ctrl.text.length)">
            <div ng-bind-html="$ctrl.safeText"></div>
            <div class="btn btn-success" ng-show="$ctrl.canEdit" ng-click="$ctrl.editInfo()" translate>Core.Buttons.Edit</div>
        </div>

        <div id="infoTabEdit" ng-if="$ctrl.editMode && $ctrl.canEdit">
            <textarea kendo-editor k-ng-model="$ctrl.text" k-options="$ctrl.textOptions" style="height:800px;"></textarea>

            <div class="btn btn-success" ng-click="$ctrl.saveInfo()" translate>Core.Buttons.Save</div>
            <div class="btn btn-danger" ng-click="$ctrl.cancelInfo()" translate>Core.Buttons.Cancel</div>

        </div>
            
        `;
    }

    angular.module("app").component("textEdit", new TextEditComponent());
}