module App {
    export class AuthService {
        public static Factory(
            $http: ng.IHttpService,
            $q: ng.IQService,
            $location: ng.ILocationService,
            $route: ng.route.IRouteService,
            $translate: angular.translate.ITranslateService,
            appConfig: AppConfig,
            localeService: LocaleService,
            localStorageService: any) {
            return new AuthService($http, $q, $location, $route, $translate, appConfig, localeService, localStorageService);
        }


        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            private $route: ng.route.IRouteService,
            private $translate: angular.translate.ITranslateService,
            private appConfig: AppConfig,
            private localeService: LocaleService,
            private localStorageService: any) {

        }

        // PROPS
        private deferredCache: ng.IDeferred<ICurrentUser> = null;
        private bearerToken = {
            value: ""
        };


        data:ICurrentUser = {
            UserId: "",
            Username: "",
            IsAuthenticated: false,
            Roles: [],
            IsAuthenticating: false, // Authentication Request ist just on the way...
            IsLoaded: false, // currentuser Object already available or still loading?
        };
        

        // PUBLIC
        init(forceReload: boolean): ng.IPromise<ICurrentUser> {
            if (this.deferredCache) return this.deferredCache.promise;
            var deferred = this.$q.defer();


            this.localeService.init().then(() => {
                if (this.data.IsLoaded && !forceReload) {
                    deferred.resolve(this.data);
                } else {
                    this.deferredCache = deferred;
                    this.getCurrentUser().then(currentUser => {
                        this.deferredCache = null;
                        deferred.resolve(currentUser);
                    }, errResponse => {
                        this.deferredCache = null;
                        deferred.reject(errResponse);
                    });
                }
            });

            return deferred.promise;
        }


        logout(): void {
            this.data.IsLoaded = false;
            this.data.IsAuthenticated = false;
            //this.appConfig.request.Login.Logout(this.$http).then(() => {
            this.localStorageService.remove('authorizationData');
            this.bearerToken.value = null;
                this.init(true).then(okResponse => {
                    var res = this.$location.path("/");
                });
            //});
        };

        login(username: string, password: string): ng.IPromise<{}> {
            var deferred = this.$q.defer();
            this.data.IsAuthenticating = true;
            //this.appConfig.request.Login.Login(this.$http, username, password).then(okResponse => {
            this.appConfig.request.Login.TokenLogin(this.$http, username, password).then(okResponse => {
                var t = {
                    token: okResponse.data ? okResponse.data.access_token : null,
                    userName: okResponse.data ? okResponse.data.userName : null
                }
                this.localStorageService.set('authorizationData', t);
                this.bearerToken.value = t.token;

                this.init(true);
                this.data.IsAuthenticating = false;
                deferred.resolve(okResponse.data);
            }, errResponse => {
                if (this.data.IsAuthenticated) this.logout();
                this.data.IsAuthenticating = false;
                deferred.reject(errResponse.data);

            });

            return deferred.promise;

        };

        loadAuth(inputUrl: string) {
            if (inputUrl && inputUrl.indexOf("external_access_token") > -1) {
                const externalToken = this.getHashValue("external_access_token");
                //console.debug("loadAuth: external Token found", externalToken);
                this.appConfig.request.Login.SwapToken(this.$http, externalToken).then(ok => {
                    this.localStorageService.set('authorizationData', ok.data);
                    window.location.reload();
                });
            } else {
                var authData = this.localStorageService.get('authorizationData');
                //console.debug(authData as any);
                if (authData) {
                    this.bearerToken.value = authData.token;
                    this.init(true);
                }
            }

        }

        kendoError(e: any, gridObject: kendo.ui.Grid): void {
            console.debug(e);
            if (gridObject) gridObject.cancelChanges();
            if (e.xhr.status === 401) {
                var path = this.$location.path();
                var res = this.$location.path('/Login').search('returnUrl', path);
            } else if (e.xhr.status === 200) {
                // e.Errors as DataSourceResult Object is not yet handled here
                var msg = "<strong>Fehler</strong></br>";
                angular.forEach(e.Errors, (val, key) => {
                    if (val.Errors)
                        angular.forEach(val.errors, errMsg => {
                            msg += key + ": " + errMsg + "</br>";
                        });
                });
                bootbox.alert(msg);

            } else {
                if (e.xhr && e.xhr.responseJson) {
                    // e.xhr.resonseJson.Errors is not yet handled here (for data validation)
                    if (e.xhr.responseJson.Translate) {
                        this.$translate(e.xhr.responseJson.Translate, e.XMLHttpRequest.responseJson.TranslateParams)
                            .then(text => {
                                bootbox.alert(text);
                            });
                    } else if (e.xhr.responseJSON.Message) {
                        bootbox.alert(e.xhr.responseJSON.Message);
                    } else {
                        bootbox.alert(e.errorThrown);
                    }

                } else {
                    bootbox.alert(e.errorThrown);
                }
            }

        }

        inRole(roleName: string): boolean {
            return this.data.Roles.indexOf(roleName) > -1;
        }

        private getBearerTokenValue() {
            if (this.bearerToken && this.bearerToken.value) return this.bearerToken.value;
            return "";
        }

        getBearerTokenObject() {
            var ret: { [key: string]: string } = {};
            if (this.bearerToken && this.bearerToken.value) {
                ret['Authorization'] = 'Bearer ' + this.bearerToken.value;
            }
            return ret;
        }


        // PRIVATE

        private refreshBranding(): void {
            // see _main-layout.scss
            //$('.brand-background').css('background-image', 'url("/Content/images/BRANDING/BACKGROUND.JPG")');
            //$('.brand-banner').css('background-image', 'url("/Content/images/BRANDING/BANNER-Dodge.JPG")');
        }

        private getCurrentUser(): ng.IPromise<ICurrentUser> {
            var deferred = this.$q.defer<ICurrentUser>();
            this.data.IsLoaded = false;
            this.appConfig.request.Login.GetCurrentUser(this.$http).then(okResponse => {
                this.data.UserId = okResponse.data.UserId;
                this.data.Username = okResponse.data.Username;
                this.data.IsAuthenticated = okResponse.data.IsAuthenticated;
                this.data.Roles = okResponse.data.Roles;

                this.refreshBranding();

                this.data.IsLoaded = true;
                deferred.resolve(this.data);

            }, errResponse => {
                console.debug(errResponse);
                deferred.reject(errResponse);

            });


            return deferred.promise;

        }

        private getHashValue(key:string) {
            var matches = location.hash.match(new RegExp(key + '=([^&]*)'));
            return matches ? matches[1] : null;
        }



    }

    angular.module("app").service("authService", ['$http', '$q', '$location', '$route', '$translate', 'appConfig', 'localeService', 'localStorageService', AuthService.Factory]);
}