angular.module('app').filter('aspDate', ['$filter', function ($filter) {
    return function (input, format) {

        if (!input) {

        } else if (angular.isDate(input)) {

        } else if (input.indexOf("/Date(") === 0) {
            input = moment(input);
            if (format === "fromNow") return input.fromNow();
            input = input.toDate();
        } else if (moment(input, moment.ISO_8601).isValid()) {
            input = moment(input, moment.ISO_8601);
            if (format === "fromNow") return input.fromNow();
            input = input.toDate();
        } else if (format === "timespan") {
            // no negative timespans
            input = input.indexOf("-") === 0 ? input.substr(1) : input;

            input = moment.duration(input);
            var h = input.hours() > 0 ? input.hours() + ":" : "";
            
            var min = input.hours() > 0 
                ? input.minutes() < 10 ? "0" + input.minutes() : input.minutes()
                : input.minutes();
            
            var sec = input.seconds() < 10 ? "0" + input.seconds() : input.seconds();
            
            var ms = input.milliseconds() < 10
                ? "00" + input.milliseconds()
                : input.milliseconds() < 100 ? "0" + input.milliseconds() : input.milliseconds();
            return h + min + ":" + sec + "." + ms;
        }

        return $filter('date')(input, format);
    };
}]);