module App {
    export class AuthInterceptor implements ng.IHttpInterceptor {

        public static Factory($q: ng.IQService, $location: ng.ILocationService, localStorageService:any) {
            return new AuthInterceptor($q, $location, localStorageService);
        }

        static $inject = ['$q', '$location', 'localStorageService'];

        constructor(
            private $q: ng.IQService,
            private $location: ng.ILocationService,
            private localStorageService: any) {

        }
        private path:string;

        public request = (config: ng.IRequestConfig): ng.IRequestConfig=> {
            this.path = this.$location.path();

            var authData = this.localStorageService.get('authorizationData');
            if (authData) {
                config.headers = config.headers || {};
                config.headers['Authorization'] = 'Bearer ' + authData.token;
            }

            return config;
        }

        public requestError = (requestFailure:any): ng.IPromise<any> => {
            return requestFailure;
        }

        public response = (response:any): ng.IPromise<any> => {
            //console.debug("response: ", response);
            return response || this.$q.when(response);
        }

        public responseError = (rejection:any): ng.IPromise<any> => {
            // data format: rejection {config: Object, data: "NotAuthorized", headers: function, status: 401, statusText: "Unauthorized"}
            //var data = angular.fromJson(rejection.data);

            if (rejection.status === 401) {
                this.$location.path('/Login').search('returnUrl', this.path);
            }
            return this.$q.reject(rejection);
        }


    }

    angular.module('app').factory('authInterceptor', ['$q', '$location', 'localStorageService', AuthInterceptor.Factory]);
}


