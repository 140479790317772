module App {
    export class OverdueController implements ng.IComponentController {
        //binding
        lastDate: Date|null;
        showText: boolean;

        //compute
        days: number;
        age: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$location', '$element', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $location: ng.ILocationService,
            private $element: ng.IRootElementService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            if (this.lastDate) {
                this.days = moment().diff(this.lastDate, 'days');
                this.age = moment(this.lastDate).fromNow();

                $(this.$element).tooltip(); 
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class OverdueComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            lastDate: '<',
            showText: '@',
        };

        controller = OverdueController;
        //templateUrl='';
        template = `
            <span class="glyphicon glyphicon-time text-success" ng-show="$ctrl.days === 0" data-toggle="tooltip" title="{{$ctrl.lastDate | aspDate: 'fromNow'}}"></span>
            <span class="glyphicon glyphicon-time text-muted" ng-show="$ctrl.days === 1" data-toggle="tooltip" title="{{$ctrl.age}}"></span>
            <span class="glyphicon glyphicon-time text-warning" ng-show="$ctrl.days === 2 || $ctrl.days === 3" data-toggle="tooltip" title="{{$ctrl.age}}"></span>
            <span class="glyphicon glyphicon-time text-danger" ng-show="$ctrl.days >3" data-toggle="tooltip" title="{{$ctrl.age}}"></span>
            <span class="text-muted" ng-show="$ctrl.showText && $ctrl.age">{{$ctrl.age}}</span>
        `;
    }

    angular.module("app").component("overdue", new OverdueComponent());
}