"use strict";

angular.module('app').filter('byteReadable', function () {
    return function (input) {
        if (input===null || input === undefined || !isFinite(input)) return input;

        if (input < 1024) return input.toFixed(1) + " B";

        input = input / 1024;
        if (input < 1024) return input.toFixed(1) + " kB";

        input = input / 1024;
        if (input < 1024) return input.toFixed(1) + " MB";

        input = input / 1024;
        return input.toFixed(1) + " GB";

    };
})