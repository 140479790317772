module App {
    export class TranslateContentController  {
        input: string;
        output: string;


        static $inject: string[] = ['$translate'];
        constructor(private $translate: angular.translate.ITranslateService) {
            this.translate(this.input);
        }

        // ========================================
        // PRIVATE
        // ========================================
        private translate(value: string): void {
            if (value.indexOf('.Log') > -1 || value.indexOf('Log##') > -1) {
                let split = value.split("##");
                let param: string = null;
                if (split.length > 1) {
                    try {
                        param = angular.fromJson(split[1]);
                    } catch (e) {

                    }
                }
                this.$translate(split[0], param).then(t => this.output = t);

            } else {
                this.output = value;
            }
        }

    }


    export class TranslateContentComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            input: '<'
        };

        controller: any = TranslateContentController;

        template: string = `
            <span>{{$ctrl.output}}</span>
        `;
    }

    angular.module("app").component("translateContent", new TranslateContentComponent());
}